import React, {useState, useEffect} from "react";
import {Container, Col} from "react-bootstrap";
import Masonry from "react-masonry-component";

import {Section, Box, ListNav} from "../../components/Core";
import WorkCard from "../../components/WorkCard";
import {designWorks1} from "../../data";

import {useDataSet} from "../../hooks/useDataSet";

const Works = () => {
    const [items, setItems] = useState([]);
    const [activeLink, setActiveLink] = useState("*");
    const [data, loading] = useDataSet()
    const [categories, catLoading] = useDataSet("categories", null)

    useEffect(() => {
        if (!loading) {
            filterBy('*')
        }
    }, [loading]);

    const filterBy = (cat) => {
        if (cat === "*") {
            setActiveLink("*");
            setItems(data.data);
        } else {
            console.log('mydata', items, cat, data.data)
            const filteredItems = data.data.filter(item => {
                console.log('item', item)
                return item.attributes.categories.data.find(el => el.attributes.category === cat)
                // if (item.attributes.categories.data.length > -1) {
                //     // return item.attributes.categories.data.reduce((prev,curr) => { console.log(curr.attributes.category === cat); return curr.attributes.category === cat})
                // }
                // return;
            })
            console.log('filtered', filteredItems)
            setActiveLink(cat);
            setItems(filteredItems);
        }
    };

    const masonryOptions = {
        transitionDuration: 1000
    };

    return (
        <> {/* <!-- Works Area --> */}
            <Section className="position-relative">
                <Container>
                    <Box mb="2.5rem" ml="-1.75rem">
                        <ListNav className="nav">
                        <li className="nav-item">
                                <a className={
                                        `nav-link font-weight-bold text-lowercase ${
                                            activeLink === "*" ? "active" : null
                                        }`
                                    }
                                    onClick={
                                        (e) => {
                                            e.preventDefault();
                                            filterBy("*");
                                        }
                                }>
                                    All works.
                                </a>
                            </li>

                        {catLoading === false ? (
                          categories.map((category, index) => (
                                <li className="nav-item" key={index} >
                                    <a
                                        className={
                                            `nav-link font-weight-bold text-lowercase ${
                                                activeLink === category.attributes.category ? "active" : null
                                            }`
                                        }
                                        onClick={
                                            (e) => {
                                                e.preventDefault();
                                                console.log('category map', category.attributes.category)
                                                filterBy(category.attributes.category);
                                            }
                                    }>
                                    {category.attributes.category}.
                                    </a>
                                </li>
                            
                          )
                          )
                       ) : (false)}
                      
                        </ListNav>
                    </Box>
                </Container>

                {
                loading === false ? (
                    <Container fluid>
                        <Masonry options={masonryOptions}
                            className={"masonry-grid row"}
                            // default ''
                        >
                            {
                                
                            items.map(item => (item.attributes.gallery.data.map((image, i) => (
                                <Col lg="3" md="4" sm="6"
                                    key={i}
                                    className="filtr-item">
                                    <WorkCard workItem={image}
                                        mb="30px"
                                        link={
                                            image.link
                                        }
                                        campaign={
                                            item.attributes.campaigns || "#"
                                        }/>
                                </Col>
                            ))))
                        }

                            {/* {items.map((item, index) => (
              <Col lg="3" md="4" sm="6" key={index} className="filtr-item">
                <WorkCard workItem={item} mb="30px" link={item.link}/>
              </Col>
            ))} */} </Masonry>
                    </Container>
                ) : (false)
            } </Section>
        </>
    );
};

export default Works;
